import './styles.scss';

class TimeField {
  init(elem, flatpickr) {
    if (!elem._flatpickr) {
      flatpickr.default(elem, {enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K'
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  function initField(container = 'body') {
    const timepickers = document.querySelectorAll(`${container} .js-sm-time-flatpickr`);
    if (timepickers.length > 0) {
      import('flatpickr')
        .then((flatpickr) => {
          timepickers.forEach(el => {
            new TimeField().init(el, flatpickr);
          });
        });
    }
  }

  initField();

  document.addEventListener('html-updated', (e) => {
    initField(e.detail ? e.detail.container : undefined);
  });
  document.addEventListener('sm-modal:opened', () => {
    initField('.js-sm-modal');
  });

  document.addEventListener('time-field:init', (e) => {
    import('flatpickr')
      .then((flatpickr) => {
        new TimeField().init(e.detail.element, flatpickr);
      });
  });
});
